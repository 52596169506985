<template>
  <loading v-if="isLoading" />
  <div v-else class="mx-4 py-4 md:mx-6 md:py-6">
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div
        class="flex flex-wrap items-center justify-between pb-5 sm:flex-nowrap"
      >
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Faktur Pembayaran
        </h3>
        <div
          class="ml-4 mt-2 flex-shrink-0"
          v-if="getPayments.data.attributes.deletedAt"
        >
          Dihapus pada:
          <span
            class="inline-flex items-center rounded-full bg-red-100 px-3 py-0.5 text-sm font-medium text-red-800"
            >{{
              dayjs(getPayments.data.attributes.deletedAt).format('LLLL')
            }}</span
          >
        </div>
      </div>
      <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <div
            class="field-inset-default relative w-full"
            :class="{ 'bg-gray-100': is_detail }"
          >
            <label for="name" class="block text-xs font-bold text-gray-700">
              No Invoice
            </label>
            <button
              type="button"
              class="flex absolute inset-y-0 top-4 right-4 cursor-pointer items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-500 hover:text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <input
              v-model="dataPembayaran.no_penjualan"
              :disabled="true"
              ref="focusNoPenjualan"
              type="text"
              name="no_penjualan"
              id="no_penjualan"
              class="input-inset-select"
              :class="{ 'bg-gray-100': is_detail }"
              placeholder="Masukkan No Penjualan yang akan dibayarkan"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="field-inset-default relative bg-gray-100">
            <label
              for="kode_stockist"
              class="block text-xs font-bold text-gray-700"
            >
              Kode Stockist
            </label>
            <input
              v-model="
                getRelationships(
                  getPayments.included,
                  getPayments.data.relationships.order.data.id
                ).attributes.destination_code
              "
              readonly
              type="text"
              name="kode_stockist"
              id="kode_stockist"
              class="input-inset-select bg-gray-100"
              placeholder="ex: SCR338"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="field-inset-default relative bg-gray-100">
            <label
              for="nama_pemilik"
              class="block text-xs font-bold text-gray-700"
            >
              Nama Pemilik
            </label>
            <input
              v-model="dataPembayaran.nama_pemilik"
              readonly
              type="text"
              name="nama_pemilik"
              id="nama_pemilik"
              class="input-inset-select bg-gray-100"
              placeholder="ex: AYATULLOH MUHAMMAD DEVA"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="field-inset-default relative bg-gray-100">
            <label
              for="kode_gudang"
              class="block text-xs font-bold text-gray-700"
            >
              Kode Gudang
            </label>
            <input
              v-model="dataPembayaran.kode_gudang"
              readonly
              type="text"
              name="kode_gudang"
              id="kode_gudang"
              class="input-inset-select bg-gray-100"
              placeholder="ex: GD123"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="field-inset-default relative bg-gray-100">
            <label
              for="wilayah_harga"
              class="block text-xs font-bold text-gray-700"
            >
              Wilayah Harga
            </label>
            <input
              v-model="dataPembayaran.wilayah_harga"
              readonly
              type="text"
              name="wilayah_harga"
              id="wilayah_harga"
              class="input-inset-select bg-gray-100"
              placeholder="ex: Wilayah 1"
            />
          </div>
        </div>
        <div class="sm:col-span-3">
          <div class="field-inset-default relative bg-gray-100">
            <label
              for="wilayah_harga"
              class="block text-xs font-bold text-gray-700"
            >
              Tanggal Faktur
            </label>
            <input
              :value="
                dayjs(getPayments.data.attributes.createdAt).format('LLLL')
              "
              readonly
              type="text"
              name="createdAt"
              id="createdAt"
              class="input-inset-select bg-gray-100"
              placeholder="ex: 30/1/2023"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Metode Pembayaran
        </h3>
      </div>
      <div
        class="flex w-full flex-row gap-x-4 gap-y-2 pb-4"
        v-for="(method, index) in dataPembayaran.payment_methods.data"
        :key="`metode-${index}`"
      >
        <div class="w-full">
          <div class="field-inset-default relative">
            <label
              for="nominal_pembayaran"
              class="block text-xs font-bold text-gray-700"
            >
              Metode Pembayaran
            </label>
            {{ getMethodName(method) }}
          </div>
        </div>
        <div class="relative w-full">
          <div class="field-inset-default">
            <label
              for="nama_pemilik"
              class="block text-xs font-bold text-gray-700"
            >
              Tanggal Pembayaran
            </label>
            {{ method.attributes.transfer_date || '-' }}
          </div>
        </div>
        <div class="relative w-full">
          <div class="field-inset-default">
            <label
              for="nama_pemilik"
              class="block text-xs font-bold text-gray-700"
            >
              Nominal Pembayaran
            </label>
            {{ method.attributes.payment_amount | toCurrency }}
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="flex justify-between">
        <div>
          <button
            @click="printPaymentMethods('cetak')"
            class="inline-flex items-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 hover:shadow-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-4 w-4 md:mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
            <span class="hidden md:block">Cetak</span>
          </button>
          <button
            @click="printPaymentMethods('download')"
            class="mx-2 inline-flex items-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 hover:shadow-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-4 w-4 md:mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
            <span class="hidden md:block">Download</span>
          </button>
        </div>
        <router-link
          :to="`/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist/${dataPembayaran.id_penjualan}`"
          class="ml-3 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
        >
          Kembali
        </router-link>
      </div>
    </div>

    <!-- MODAL SUCCESS-->
    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal
          @modal-action="successModal"
          :link="`/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist/${dataPembayaran.id_penjualan}`"
        >
          <template v-slot:svg>
            <div
              class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Data berhasil disimpan
            </h3>
          </template>
        </modal>
      </div>
    </transition>

    <!-- MODAL ERROR -->
    <transition name="fade">
      <div
        v-if="getError"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal
          @modal-action="failedModal"
          :link="`/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist/${dataPembayaran.id_penjualan}`"
        >
          <template v-slot:svg>
            <div
              class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              {{ getError }}
            </h3>
          </template>
        </modal>
      </div>
    </transition>
    <!-- print -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="cetakPdf.preview"
      :enable-download="cetakPdf.download"
      :paginate-elements-by-height="5000"
      :filename="cetakPdf.filename"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
      ref="html2Pdf"
    >
      <div slot="pdf-content" class="mx-2 mb-10">
        <h1>Detail Faktur Pembayaran</h1>
        <table class="mb-2 w-full">
          <thead>
            <tr>
              <th class="text-left">Metode Pembayaran</th>
              <th class="text-left">Tanggal Transfer</th>
              <th class="text-left">Nominal</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(payment, index) in getPaymentMethods.data" :key="index">
              <td>
                {{
                  payment.relationships['office-bank'].data
                    ? getRelationships(
                        getPaymentMethods.included,
                        payment.relationships['office-bank'].data.id
                      ).attributes.bank_name
                    : null
                }}
              </td>
              <td>
                {{ dayjs(payment.attributes.transfer_date).format('LL LT') }}
              </td>
              <td>
                {{ payment.attributes.payment_amount | toCurrency }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vue-html2pdf>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Modal from '@/components/Modal.vue';
import VueHtml2pdf from 'vue-html2pdf';
import { getBalanceUsedMethodName } from '@/services/utils.service';

export default {
  name: 'TambahTransaksiPembayaran',
  components: {
    Modal,
    VueHtml2pdf,
  },
  data() {
    return {
      is_detail: false,
      success: false,
      failed: false,
      dataPembayaran: {
        id: '',
        id_penjualan: null,
        no_penjualan: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        payment_methods: [],
        banks: [],
      },
      cetakPdf: {
        preview: false,
        download: false,
        filename: '',
      },
    };
  },
  created() {
    if (this.$route.params.id_pembayaran) {
      this.is_detail = true;
      this.dataPembayaran.id = this.$route.params.id_pembayaran;
      this.dataPembayaran.id_penjualan = this.$route.params.id;

      this.fetchPaymentMethods({
        id: this.dataPembayaran.id,
        include: 'office-bank',
        fields: {
          'payment-methods':
            'payment_amount,office-bank,transfer_date,createdAt,balance_used',
          'office-banks': 'bank_name',
        },
      }).then((response) => {
        this.dataPembayaran.payment_methods = response.data;
      });

      this.fetchPayment({
        id: this.dataPembayaran.id,
        include: 'order',
        fields: {
          payments: 'code,order,createdAt,deletedAt',
          orders:
            'destination_office_code,destination_code,destination_office,origin_warehouse_code,area_code',
        },
      }).then((response) => {
        this.dataPembayaran.no_penjualan = response.data.data.attributes.code;
        this.dataPembayaran.kode_stockist = this.getRelationships(
          response.data.included,
          response.data.data.relationships['order'].data.id
        ).attributes.destination_office_code;
        this.dataPembayaran.nama_pemilik = this.getRelationships(
          response.data.included,
          response.data.data.relationships['order'].data.id
        ).attributes.destination_office;
        this.dataPembayaran.kode_gudang = this.getRelationships(
          response.data.included,
          response.data.data.relationships['order'].data.id
        ).attributes.origin_warehouse_code;
        this.dataPembayaran.wilayah_harga = this.getRelationships(
          response.data.included,
          response.data.data.relationships['order'].data.id
        ).attributes.area_code;
        this.cetakPdf.filename = this.getPayments.data.attributes.code;
      });
    }
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getError: 'payments/getError',
      getPayment: 'payments/getPayment',
      getPayments: 'payments/getPayments',
      getPaymentMethods: 'payments/getPaymentMethods',
      isLoading: 'payments/getLoading',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchPayment: 'payments/fetchPayment',
      fetchPaymentMethods: 'payments/fetchPaymentPaymentMethods',
    }),
    printPaymentMethods(type) {
      if (type == 'cetak') {
        this.cetakPdf.preview = true;
        this.cetakPdf.download = false;
      } else if (type == 'download') {
        this.cetakPdf.preview = false;
        this.cetakPdf.download = true;
      }
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 0);
    },
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    clearData() {
      this.dataPembayaran = {
        id: '',
        no_penjualan: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        saldo_awal: null,
        total_penjualan: null,
        invoices: [],
      };
    },
    getRelationships(resource, id) {
      let data = resource?.filter(function (el) {
        return el?.id === id;
      });
      return data?.[0];
    },
    getMethodName: function (method) {
      return (
        this.getRelationships(
          this.dataPembayaran.payment_methods.included,
          method.relationships['office-bank']?.data?.id
        )?.attributes.bank_name ||
        getBalanceUsedMethodName(method.attributes.balance_used)
      );
    },
  },
};
</script>
