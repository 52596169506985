<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <LaporanHeader />

      <section class="mt-10">
        <div
          class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
        >
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Validasi Laporan Penjualan Kantor Pelayanan
          </h3>
        </div>
        <div class="flex flex-col">
          <div class="flex items-center justify-end space-x-2">
            <base-select
              v-model="is_validated"
              @change="filterByValidated"
              :expand="false"
            >
              <option :value="null">Semua</option>
              <option :value="true">Tervalidasi</option>
              <option :value="false">Belum Tervalidasi</option>
            </base-select>
            <base-search
              v-model="search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <Datatable
                :total="getOrderBundles.meta.page.total"
                :perPage="getOrderBundles.meta.page.perPage"
                :currentPage="getOrderBundles.meta.page.currentPage"
                @pagechanged="onPageChange"
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor Laporan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nama Laporan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Deskripsi
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Tanggal Laporan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <tbody v-if="getOrderBundles.data?.length > 0">
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getOrderBundles.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click="tableDetails(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ data.attributes.code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ data.attributes.name }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ data.attributes.description }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ dayjs(data.attributes.createdAt).format('ll LT') }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                      >
                        <span
                          v-if="data.attributes.is_validated"
                          class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                        >
                          Tervalidasi
                        </span>
                        <span
                          v-else
                          class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                        >
                          Belum Tervalidasi
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        colspan="5"
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        Tidak ada data
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>
        </div>
      </section>

      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="openDetail"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
            >
              <div class="flex mb-8 justify-between">
                <div class="flex items-center space-x-4">
                  <div>
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Detail Laporan
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Data laporan yang telah dibuat
                    </p>
                  </div>
                  <router-link
                    v-if="!dataLaporan.attributes.is_submitted"
                    :to="'restock/' + dataLaporan.id"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2 h-6 w-6 cursor-pointer hover:text-green-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </router-link>
                </div>
                <div>
                  <img
                    src="@/assets/images/logo.png"
                    alt=""
                    class="flex justify-end"
                  />
                </div>
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">No Laporan</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.code }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Tanggal Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dayjs(dataLaporan.attributes.updatedAt).format('ll LT')
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Validasi</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span
                        v-if="dataLaporan.attributes.is_validated"
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Valid
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                      >
                        Belum tervalidasi
                      </span>
                    </dd>
                  </div>
                </dl>
                <dl class="flex mt-6 flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Nama Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.name }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Deskripsi Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.description }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Dikirim</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span
                        v-if="dataLaporan.attributes.is_submitted"
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Terkirim ke Pusat
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                      >
                        Belum Terkirim
                      </span>
                    </dd>
                  </div>
                </dl>
                <!-- {{getRelationships(getOrderBundles, dataLaporan}} -->
                <div class="my-6 sm:col-span-6">
                  <dt class="text-sm font-bold text-gray-700">
                    Daftar Restock
                  </dt>
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Nomor PO
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Tanggal PO
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Produk
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Berat
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Harga
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="bg-white"
                          v-for="(data, index) in dataLaporan.relationships[
                            'orders'
                          ].data"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.origin_code
                            }}
                            <br />
                            <span class="text-xs">
                              {{
                                getRelationships(getOrderBundles, data.id)
                                  .attributes.destination_code
                              }}
                            </span>
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              dayjs(
                                getRelationships(getOrderBundles, data.id)
                                  .attributes.updatedAt
                              ).format('ll LT')
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.item_count
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.grand_total_weight
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.final_price | toCurrency
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Jumlah Pembayaran
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="bg-white"
                          v-for="(data, index) in dataLaporan.relationships
                            .paymentBundles.data"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.payment_amount | toCurrency
                            }}
                          </td>
                          <td class="w-10">
                            <base-button
                              :disabled="
                                getRelationships(getOrderBundles, data.id)
                                  .attributes.is_verified
                              "
                              @click="verify(data.id)"
                            >
                              {{
                                getRelationships(getOrderBundles, data.id)
                                  .attributes.is_verified
                                  ? 'Terverifikasi'
                                  : 'Verifikasi'
                              }}
                            </base-button>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </div>
              </div>
              <div class="flex justify-end">
                <button
                  v-if="!dataLaporan.attributes.is_validated"
                  @click="validasi(dataLaporan)"
                  class="focus:outline-none mr-2 inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                  Validasi
                </button>
                <button
                  @click="openDetail = !openDetail"
                  type="button"
                  class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import LaporanHeader from '@/components/laporan/header.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    LaporanHeader,
    BaseSearch,
  },

  data() {
    return {
      search: null,
      is_validated: false,
      openDetail: false,
      dataLaporan: {},
    };
  },

  computed: {
    ...mapGetters({
      getOrderBundles: 'order_bundles/getOrderBundles',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrderBundles: 'order_bundles/fetchOrderBundles',
      updateOrderBundle: 'order_bundles/updateOrderBundle',
      updatePaymentBundles: 'payment_bundles/updatePaymentBundles',
      verifyPaymentBundles: 'payment_bundles/verifyPaymentBundles',
      validateOrderBundle: 'order_bundles/validateOrderBundle'
    }),
    debounceSearch: debounce(function () {
      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        search: this.search,
        officeType: 'kantor_pelayanan',
      });
    }, 300),
    verify(idPaymentBundles) {
      this.verifyPaymentBundles({ id: idPaymentBundles }).then(() => {
        this.fetchOrderBundles({
          pageNumber: 1,
          pageSize: 5,
          is_submitted: true,
          is_validated: this.is_validated,
          officeType: 'kantor_pelayanan',
        });
      });
    },
    onPageChange(page) {
      this.fetchOrderBundles({
        pageNumber: page,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        search: this.search,
        officeType: 'kantor_pelayanan',
      });
    },
    tableDetails(data) {
      this.openDetail = !this.openDetail;
      this.dataLaporan = data;
    },
    filterByValidated() {
      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        officeType: 'kantor_pelayanan',
      });
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    validasi(data) {
      this.validateOrderBundle(data.id).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrderBundles({
            pageNumber: 1,
            pageSize: 5,
            is_submitted: true,
            is_validated: this.is_validated,
            officeType: 'kantor_pelayanan',
          });
        }
      });
    },
    onChangeNominalPembayaran(e, index) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.metodePembayaran[index].nominalPembayaran = value;
      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
  },
  created() {
    this.fetchOrderBundles({
      pageNumber: 1,
      pageSize: 5,
      is_submitted: true,
      is_validated: this.is_validated,
      officeType: 'kantor_pelayanan',
    });
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
