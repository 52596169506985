<template>
  <div class="mx-auto my-4 px-4 sm:px-4 lg:px-8">
    <div class="space-y-6">
      <div class="flex justify-end gap-x-2">
        <base-input label="Pilih Stockist" with-label>
          <office-select-search
            :filter="{
              office_type: 'stockist',
            }"
            :shadow="false"
            with-name
            v-model="filter.office"
            v-on:change="handleFilter"
          />
        </base-input>
        <period-select
          v-model="filter.period_uuid"
          v-on:change="handleFilter"
        />
      </div>
      <base-empty v-if="!filter.office || !filter.period_uuid"
        >Stockist atau periode belum dipilih</base-empty
      >
      <template v-else>
        <p class="text-right text-sm">
          Saldo Stockist :
          <b>{{
            +getOrderPaymentReportMeta.grand_total_office_balance | toCurrency
          }}</b>
        </p>
        <LaporanKeuanganStockistTable />
      </template>
    </div>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import BaseEmpty from '@/components/base/BaseEmpty.vue';
import PeriodSelect from '@/components/period/PeriodSelect.vue';
import OfficeSelectSearch from '@/components/office/office-select-search.vue';
import { mapGetters, mapActions } from 'vuex';
import { getCurrent } from '@/utils/date';
import LaporanKeuanganStockistTable from '@/components/laporan/laporan-keuangan-stockist/LaporanKeuanganStockistTable.vue';

export default {
  components: {
    PeriodSelect,
    BaseInput,
    OfficeSelectSearch,
    BaseEmpty,
    LaporanKeuanganStockistTable
  },
  data() {
    return {
      filter: {
        is_point: null,
        period_uuid: null,
        office: null,
        year: getCurrent('year'),
      },
      page: {
        size: 10,
        number: 1,
      }
    };
  },
  computed: {
    ...mapGetters({
      getLoadingOrderPaymentReportMeta:
        'offices/getLoadingOrderPaymentReportMeta',
      getLoadingOrderPaymentHistory: 'offices/getLoadingOrderPaymentHistory',
      getLoadingPeriod: 'periods/getLoading',
      getOrderPaymentReportMeta: 'offices/getOrderPaymentReportMeta',
      getOrderLoading: 'orders/getLoading',
      getPeriod: 'periods/getPeriod',
    }),
    isLoading() {
      return (
        this.getLoadingOrderPaymentReportMeta ||
        this.getOrderLoading ||
        this.getLoadingOrderPaymentHistory ||
        this.getLoadingPeriod
      );
    },
  },
  methods: {
    ...mapActions({
      fetchOrderPaymentReportMeta: 'offices/fetchOrderPaymentReportMeta',
      fetchOrderPaymentHistory: 'offices/fetchOrderPaymentHistory',
      fetchOrders: 'orders/fetchOrders',
      fetchPayments: 'payments/fetchPayments',
      fetchCurrentPeriod: 'periods/fetchCurrentPeriod',
      fetchPeriods: 'periods/fetchPeriods',
    }),
    handleFilter() {
      if (this.filter.office && this.filter.period_uuid) {
        this.loadData({ resetPage: true });
      }
    },
    handleChangePage(page) {
      this.page.number = page;

      this.loadData();
    },
    async setCurrentPeriod() {
      await this.fetchPeriods({
        'filter[year]': this.filter.year,
      });
      await this.fetchCurrentPeriod();

      this.filter.period_uuid = this.getPeriod.data.id;
    },
    loadData({ resetPage } = {}) {
      if (resetPage) {
        this.page.number = 1;
      }

      this.fetchOrderPaymentReportMeta({
        'filter[office_uuid]': this.filter.office.id,
        'filter[period_uuid]': this.filter.period_uuid,
      });
      this.fetchOrderPaymentHistory({
        'filter[office_uuid]': this.filter.office.id,
        'filter[period_uuid]': this.filter.period_uuid,
      });
    },
  },
  created() {
    this.setCurrentPeriod();
  },
};
</script>
