<template>
  <div class="mt-8">
    <div class="space-y-8 px-4 sm:px-4 lg:px-8">
      <LaporanHeader />

      <section class="space-y-4">
        <div
          class="border-b border-gray-200 pb-4 sm:flex sm:items-center sm:justify-between"
        >
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Validasi Laporan Penjualan SC
          </h3>
        </div>
        <div class="flex flex-col">
          <div class="flex items-center justify-end space-x-2">
            <input
              v-model="searchSC"
              @input="debounceSearchSC"
              type="text"
              name="search"
              id="search"
              class="block rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
              placeholder="Cari kode kantor SC"
            />

            <div class="relative w-1/12 rounded-md shadow-sm">
              <select
                v-model="is_validated"
                @change="filterByValidated"
                class="inline-flex w-full appearance-none justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm"
              >
                <option :value="null">Semua</option>
                <option :value="true">Tervalidasi</option>
                <option :value="false">Belum Tervalidasi</option>
              </select>
            </div>
            <!-- filter date -->
            <!-- Start From -->
            <label class="ml-4" for="from">Dari</label>
            <div class="flex ml-4 sm:flex-row sm:gap-x-4">
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  @change="onChangeDate"
                  v-model="startDate"
                  type="date"
                  name="filter"
                  id="from"
                  class="focus:outline-none block w-full rounded-md border-gray-300 py-2 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- End From -->
            <label class="ml-4" for="to">Sampai</label>
            <div class="flex ml-4 sm:flex-row sm:gap-x-4">
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  @change="onChangeDate"
                  v-model="endDate"
                  type="date"
                  name="filter"
                  id="to"
                  class="focus:outline-none block w-full rounded-md border-gray-300 py-2 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
                />
              </div>
            </div>
            <!-- end filter -->
            <base-search
              v-model="search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <div class="flex sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full align-middle sm:px-6 lg:px-8">
              <Datatable
                :total="getOrderBundles?.meta.page.total"
                :perPage="getOrderBundles?.meta.page.perPage"
                :currentPage="getOrderBundles?.meta.page.currentPage"
                @pagechanged="onPageChange"
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor Laporan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Catatan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Total Laporan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <tbody v-if="getOrderBundles.data?.length > 0">
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getOrderBundles.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click="tableDetails(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        <p class="font-bold">{{ data.attributes.code }}</p>
                        <p class="text-gray-500">
                          {{ data.attributes.name ?? '-' }}
                        </p>
                        <p class="text-gray-500">
                          {{ data.attributes.createdAt | formatDate }}
                        </p>
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ data.attributes.description ?? '-' }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                      >
                        {{ data.attributes.bundle_report_price | toCurrency }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                      >
                        <span
                          v-if="data.attributes.is_validated"
                          class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                        >
                          Tervalidasi
                        </span>
                        <span
                          v-else
                          class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                        >
                          Belum Tervalidasi
                        </span>
                      </td>
                    </tr>
                    <tr class="border-t border-gray-200">
                      <td
                        colspan="2"
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        Total
                      </td>
                      <td
                        class="px-6 text-right text-sm font-medium text-gray-900"
                      >
                        {{
                          getOrderBundle.data?.grand_total_order_bundle_price
                            | toCurrency
                        }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        colspan="4"
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        Tidak ada data
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>
        </div>
      </section>

      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="openDetail"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
            >
              <div class="flex mb-8 justify-between">
                <div class="flex items-center space-x-4">
                  <div>
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Detail Laporan
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Data laporan yang telah dibuat
                    </p>
                  </div>
                  <router-link
                    v-if="!dataLaporan.attributes.is_submitted"
                    :to="'restock/' + dataLaporan.id"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2 h-6 w-6 cursor-pointer hover:text-green-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </router-link>
                </div>
                <div>
                  <img
                    src="@/assets/images/logo.png"
                    alt=""
                    class="flex justify-end"
                  />
                </div>
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">No Laporan</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.code }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Tanggal Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dayjs(dataLaporan.attributes.updatedAt).format('ll LT')
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Validasi</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span
                        v-if="dataLaporan.attributes.is_validated"
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Valid
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                      >
                        Belum tervalidasi
                      </span>
                    </dd>
                  </div>
                </dl>
                <dl class="flex mt-6 flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Nama Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.name }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Deskripsi Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.description }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Dikirim</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <span
                        v-if="dataLaporan.attributes.is_submitted"
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Terkirim ke Pusat
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                      >
                        Belum Terkirim
                      </span>
                    </dd>
                  </div>
                </dl>
                <!-- {{getRelationships(getOrderBundles, dataLaporan}} -->
                <div class="my-6 sm:col-span-6">
                  <dt class="text-sm font-bold text-gray-700">
                    Daftar Restock
                  </dt>
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Nomor PO
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Tanggal PO
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Produk
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Berat
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Harga
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="bg-white"
                          v-for="(data, index) in dataLaporan.relationships[
                            'orders'
                          ].data"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.origin_code
                            }}
                            <br />
                            <span class="text-xs">
                              {{
                                getRelationships(getOrderBundles, data.id)
                                  .attributes.destination_code
                              }}
                            </span>
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              dayjs(
                                getRelationships(getOrderBundles, data.id)
                                  .attributes.updatedAt
                              ).format('ll LT')
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.item_count
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.grand_total_weight
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.grand_total_report_price
                                | toCurrency
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="4"
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            Total
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              dataLaporan.attributes.bundle_report_price
                                | toCurrency
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Jumlah Pembayaran
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="bg-white"
                          v-for="(data, index) in dataLaporan.relationships
                            .paymentBundles.data"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{
                              getRelationships(getOrderBundles, data.id)
                                .attributes.payment_amount | toCurrency
                            }}
                          </td>
                          <td class="px-6">
                            <button
                              @click="verify(data.id)"
                              class="px-3 py-2 text-sm font-medium"
                              :class="
                                getRelationships(getOrderBundles, data.id)
                                  .attributes.is_verified
                                  ? 'inline-flex rounded-md border border-gray-300 bg-gray-300 py-2 px-4 text-sm font-medium text-white shadow-sm'
                                  : 'focus:outline-none inline-flex cursor-pointer items-center rounded-md border border-transparent bg-green-600 leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
                              "
                            >
                              {{
                                getRelationships(getOrderBundles, data.id)
                                  .attributes.is_verified
                                  ? 'Terverifikasi'
                                  : 'Belum Terverifikasi'
                              }}
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </div>
              </div>

              <!-- Metode Pembayaran -->
              <!-- tampilkan pembayaran jika laporannya pny utang -->
              <div
                v-if="dataLaporan.attributes.has_debt"
                class="mb-5 rounded-lg bg-white p-4 sm:p-6"
              >
                <div class="sm:col-span-6">
                  <div class="border-gray-200 pb-5">
                    <h3 class="text-lg font-bold leading-6 text-gray-900">
                      Metode Pembayaran
                    </h3>
                    <p class="mt-2 max-w-4xl text-sm text-gray-500">
                      Terdapat Utang Pada Laporan Ini, Silakan Tambah Metode
                      Pembayaran.
                    </p>
                  </div>
                  <div
                    class="flex w-full flex-row gap-x-4 gap-y-2 pb-4"
                    v-for="(input, index) in metodePembayaran"
                    :key="`metode-${index}`"
                  >
                    <div class="w-full">
                      <div class="field-inset-default relative">
                        <label
                          for="nominal_pembayaran"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Metode Pembayaran
                        </label>
                        <select
                          v-model="metodePembayaran[index].jenisPembayaran"
                          type="text"
                          name="nominal_pembayaran"
                          id="wilayah_harga"
                          class="input-inset-select"
                          placeholder="Pilih Jenis Pembayaran"
                          v-on:change="handleChangeMethod(index)"
                        >
                          <option :value="null">Pilih Metode Pembayaran</option>
                          <option
                            v-for="method in paymentMethods"
                            :key="method.id"
                            :value="{
                              id: method.id,
                              name: method.name,
                              value: method.value,
                            }"
                          >
                            {{ method.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="w-full"
                      v-if="
                        metodePembayaran[index].jenisPembayaran?.name === 'Bank'
                      "
                    >
                      <div class="field-inset-default relative">
                        <label
                          for="nominal_pembayaran"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Pilih Bank
                        </label>
                        <select
                          v-model="metodePembayaran[index].officeBank"
                          type="text"
                          name="nominal_pembayaran"
                          id="wilayah_harga"
                          class="input-inset-select"
                          placeholder="Pilih Jenis Pembayaran"
                        >
                          <option :value="null">Pilih Bank</option>
                          <option
                            v-for="officeBank in getBanksByOffice.data"
                            :key="officeBank.id"
                            :value="{
                              id: officeBank.id,
                              name: officeBank.bank_name,
                            }"
                          >
                            {{ officeBank.attributes.bank_name }} ({{
                              officeBank.attributes.account_number
                            }})
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="relative w-full">
                      <div class="field-inset-default">
                        <label
                          for="nama_pemilik"
                          class="block text-xs font-bold text-gray-700"
                        >
                          Nominal Pembayaran
                        </label>
                        <input
                          ref="nominal"
                          @input="(e) => onChangeNominalPembayaran(e, index)"
                          type="text"
                          name="nama_pemilik"
                          id="nama_pemilik"
                          class="input-inset-select"
                          placeholder="Masukkan Nominal Pembayaran"
                        />
                      </div>
                      <button
                        @click="removeField(index)"
                        type="button"
                        class="focus:outline-none flex absolute inset-y-0 -top-2 -right-2 h-5 w-5 items-center rounded-full border border-transparent bg-red-300 p-1 text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3 w-3"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <button
                      @click="bayarUtang(dataLaporan, index)"
                      class="focus:outline-none mr-2 inline-flex cursor-pointer items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Bayar
                    </button>
                  </div>

                  <div class="flex justify-end">
                    <button
                      @click="addField"
                      type="button"
                      class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                    >
                      Tambah Metode
                    </button>
                  </div>
                </div>
              </div>

              <div class="flex justify-end">
                <button
                  v-if="!dataLaporan.attributes.is_validated"
                  @click="validasi(dataLaporan)"
                  class="focus:outline-none mr-2 inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                  Validasi
                </button>
                <button
                  @click="openDetail = !openDetail"
                  type="button"
                  class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <loading v-if="isLoading"></loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { beginningOfMonth, endOfMonth } from '@/services/utils.service';
import { StorageService } from '@/services/storage.service';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import LaporanHeader from '@/components/laporan/header.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    LaporanHeader,
    BaseSearch,
  },
  data() {
    const begin = beginningOfMonth(new Date());
    const end = endOfMonth(new Date());

    const startDate = dayjs(begin).format('YYYY-MM-DD');
    const endDate = dayjs(end).format('YYYY-MM-DD');
    return {
      search: null,
      is_validated: false,
      openDetail: false,
      dataLaporan: {},
      startDate,
      endDate,
      searchSC: '',
      selectedSC: {},
      metodePembayaran: [
        {
          jenisPembayaran: null,
          officeBank: null,
          nominalPembayaran: null,
        },
      ],
      selectedPaymentMethod: [
        {
          officeBank: null,
          jenisPembayaran: null,
          nominalPembayaran: null,
        },
      ],
      paymentMethods: [
        {
          name: 'Cash',
          value: 'cash',
        },
        {
          name: 'Fee',
          value: 'fee',
        },
        {
          name: 'Rabat',
          value: 'rabat',
        },
        {
          name: 'Uang Jalan',
          value: 'uang_jalan',
        },
        {
          name: 'Bank',
          value: 'bank',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      getOrderBundles: 'order_bundles/getOrderBundles',
      getBanksByOffice: 'banks/getBanksByOffice',
      getOrderBundle: 'order_bundles/getOrderBundle',
      orderBundleLoading: 'order_bundles/getLoading',
    }),
    isLoading() {
      return this.orderBundleLoading;
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOffices: 'offices/fetchOffices',
      fetchOrderBundles: 'order_bundles/fetchOrderBundles',
      updateOrderBundle: 'order_bundles/updateOrderBundle',
      updatePaymentBundles: 'payment_bundles/updatePaymentBundles',
      verifyPaymentBundles: 'payment_bundles/verifyPaymentBundles',
      createPaymentBundles: 'payment_bundles/createPaymentBundles',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      fetchTotalTransaction: 'order_bundles/fetchTotalTransaction',
      validateOrderBundle: 'order_bundles/validateOrderBundle'
    }),
    onChangeDate(e) {
      if (e.target.id === 'from') {
        this.startDate = e.target.value;
      } else if (e.target.id === 'to') {
        this.endDate = e.target.value;
      }
      let params = {
        fromDate: this.startDate,
        toDate: this.endDate,
        office_id: this.selectedSC.id,
      };
      this.loading = true;
      this.fetchTotalTransaction({ params }).then(() => (this.loading = false));
    },

    handleChangeMethod(index) {
      this.metodePembayaran[index].officeBank = null;

      if (this.metodePembayaran[index].jenisPembayaran) {
        this.fetchBanksByOffice({
          office_id: StorageService.getUser().office_id,
          type: this.metodePembayaran[index].jenisPembayaran.value,
          isActive: true,
        });
      }
    },
    getDataDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        const officeBankByType = this.officeBanks.filter(
          (officeBank) =>
            officeBank.attributes.office_bank_type ===
            param.jenisPembayaran.value
        );
        let data = {
          office_bank_id: param.officeBank
            ? param.officeBank.id
            : officeBankByType[0]?.id ?? null,
          payment_amount: parseInt(param.nominalPembayaran),
          is_using_balance: false, // default value for stockist
        };
        dataDetail.push(data);
      });
      return dataDetail;
    },
    bayarUtang(orderBundles, index) {
      const payload = {
        data: {
          type: 'payment-bundles',
          attributes: {
            payment_amount: this.getDataDetails(this.metodePembayaran)[index]
              .payment_amount,
            payment_type: 'non_balance',
          },
          relationships: {
            'order-bundle': {
              data: {
                type: 'order-bundles',
                id: orderBundles.id,
              },
            },
            'office-bank': {
              data: {
                type: 'office-banks',
                id: this.getDataDetails(this.metodePembayaran)[index]
                  .office_bank_id,
              },
            },
          },
        },
      };
      this.createPaymentBundles({ payload }).then(() => {
        this.openDetail = false;
        this.fetchOrderBundles({
          pageNumber: 1,
          pageSize: 5,
          is_submitted: true,
          is_validated: this.is_validated,
          officeType: 'stockist_center',
        });
      });
    },
    addField() {
      this.metodePembayaran.push({
        jenisPembayaran: null,
        officeBank: null,
        nominalPembayaran: null,
      });
    },
    resetOfficeBank(index) {
      this.metodePembayaran[index].officeBank = null;
    },
    removeField(index) {
      this.metodePembayaran.splice(index, 1);
    },
    verify(idPaymentBundles) {
      this.verifyPaymentBundles({ id: idPaymentBundles }).then(() => {
        this.fetchOrderBundles({
          pageNumber: 1,
          pageSize: 5,
          is_submitted: true,
          is_validated: this.is_validated,
          officeType: 'stockist_center',
        });
      });
    },
    debounceSearch: debounce(function () {
      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        search: this.search,
        officeType: 'stockist_center',
      });
    }, 300),
    debounceSearchSC: debounce(function () {
      this.fetchOffices({
        office_type: 'stockist_center',
        search: this.searchSC,
      }).then((res) => {
        const params = {
          fromDate: this.startDate,
          toDate: this.endDate,
          office_id: res.data.data[0].id,
        };

        this.selectedSC = res.data.data[0];
        this.searchSC = res.data.data[0].attributes.code;

        this.fetchTotalTransaction({ params });
      });
    }, 800),
    onPageChange(page) {
      this.fetchOrderBundles({
        pageNumber: page,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        search: this.search,
        officeType: 'stockist_center',
      });
    },
    tableDetails(data) {
      this.openDetail = !this.openDetail;
      this.dataLaporan = data;
    },
    filterByValidated() {
      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        is_submitted: true,
        is_validated: this.is_validated,
        officeType: 'stockist_center',
      });
    },
    getRelationships(resource, id) {
      let data = resource?.included?.filter(function (el) {
        return el.id === id;
      });
      return data[0] ? data[0] : undefined;
    },
    validasi(data) {
      this.validateOrderBundle(data.id).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrderBundles({
            pageNumber: 1,
            pageSize: 5,
            is_submitted: true,
            is_validated: this.is_validated,
            officeType: 'stockist_center',
          });
        }
      });
    },
    onChangeNominalPembayaran(e, index) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.metodePembayaran[index].nominalPembayaran = value;
      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
  },
  created() {
    this.fetchBanksByOffice({
      office_id: StorageService.getUser().office_id,
    }).then((res) => {
      const officeBankType = new Set([
        ...res.data.data.map(
          (officeBank) => officeBank.attributes.office_bank_type
        ),
      ]);

      this.paymentMethods = this.paymentMethods.filter((method) => {
        return [...officeBankType].includes(method.value);
      });
      this.officeBanks = res.data.data;
    });

    this.fetchOffices({ office_type: 'stockist_center ' }).then(() => {
      const params = {
        fromDate: this.startDate,
        toDate: this.endDate,
      };

      this.fetchTotalTransaction({ params });
    });

    this.fetchOrderBundles({
      pageNumber: 1,
      pageSize: 5,
      is_submitted: true,
      is_validated: this.is_validated,
      officeType: 'stockist_center',
    });
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
