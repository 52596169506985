<template>
  <div class="mx-4 py-4 md:mx-6 md:py-6">
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Tambah Saldo Stockist
        </h3>
      </div>
      <!-- Replace with your content -->
      <div class="py-2">
        <div class="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <div class="field-inset-default w-full bg-gray-100">
              <label
                for="tanggal"
                class="block text-xs font-bold text-gray-700"
              >
                Tanggal
              </label>
              <input
                v-model="form.date"
                disabled
                type="date"
                name="tanggal"
                id="tanggal"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <div class="field-inset-default relative w-full">
              <label for="name" class="block text-xs font-bold text-gray-700">
                Kode Stockist
              </label>
              <button
                type="button"
                @click="openModalStockist"
                class="absolute inset-y-0 top-4 right-4 flex cursor-pointer items-center pl-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-500 hover:text-gray-700"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <input
                v-model="form.code"
                ref="focusNamaStockist"
                @blur="searchStockist"
                type="text"
                name="office_code"
                id="office_code"
                class="block w-full border-0 px-0 py-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Masukkan Kode Stockist"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="nama_stockist"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Stockist
              </label>
              <input
                v-model="form.name"
                readonly
                type="text"
                name="nama_stockist"
                id="nama_stockist"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                disabled
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative">
              <label
                for="topup_type"
                class="block text-xs font-bold text-gray-700"
              >
                Tipe Topup
              </label>
              <select
                v-model="form.topupType"
                id="topup_type"
                placeholder="Pilih Tipe Topup"
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              >
                <option :value="null">Pilih Tipe Topup</option>
                <option
                  v-for="(type, index) in getTopupTypes.data"
                  :value="type"
                  :key="index"
                >
                  {{ type.attributes.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative">
              <label
                for="deposit_topup"
                class="block text-xs font-bold text-gray-700"
              >
                Nominal Topup
              </label>
              <div class="inline-flex w-full">
                <div class="flex items-center">
                  <span class="text-gray-500 sm:text-sm">Rp</span>
                </div>
                <input
                  type="text"
                  name="deposit_topup"
                  id="deposit_topup"
                  @input="(e) => onChangeNominalPembayaran(e)"
                  class="ml-2 block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Nominal Topup"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <base-button @click="saveForm"> Simpan </base-button>
        <base-button color="white" to="/kasir/topup/saldo-stockist">
          Kembali
        </base-button>
      </div>
    </div>

    <!-- Modal Succes -->

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal @modal-action="successModal" link="/kasir/topup/saldo-stockist">
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Data berhasil disimpan
            </h3>
          </template>
        </modal>
      </div>
    </transition>

    <OfficeModal
      :is-visible="openListStockist"
      @closeKantorModal="() => (openListStockist = false)"
      @onSearchKantor="debounceSearch"
      @onPageChangeKantor="onPageChange"
      @change-office="changeStockist"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import { debounce } from 'debounce';
import Modal from '@/components/Modal.vue';
import OfficeModal from '@/components/office/office-modal.vue';

export default {
  name: 'TambahSaldoStockistCenter',
  components: {
    Modal,
    OfficeModal,
  },
  data() {
    return {
      success: false,
      failed: false,
      openListStockist: false,
      topupType: [
        {
          id: 1,
          name: 'Rabat/Bonus',
        },
        {
          id: 2,
          name: 'Topup',
        },
      ],
      search: null,
      form: {
        date: dayjs().format('YYYY-MM-DD'),
        id: null,
        code: null,
        name: null,
        deposit_topup: null,
        topupType: null,
      },
      formatLang: 'id-ID',
      formatStyle: 'currency',
      formatCurrency: 'IDR',
      // setup_value: null,
      barang_value: null,
    };
  },
  created() {
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.fetchTopupTypes();
    this.setEscape(handleEscape);
    // this.fetchMultiplier().then((response) => {
    //   this.setup_value = response.data.data[0].attributes.setup_value;
    // });
  },
  computed: {
    ...mapGetters({
      getStockistsCenter: 'stockists_center/getStockistsCenter',
      getMultiplier: 'setups/getMultiplier',
      getOffices: 'simple_offices/getSimpleOffices',
      getTopupTypes: 'topup_types/getTopupTypes',
    }),
  },
  methods: {
    ...mapActions({
      topupStockistCenter: 'stockists_center/topupStockistCenter',
      fetchMultiplier: 'setups/fetchMultiplier',
      fetchOffices: 'simple_offices/fetchSimpleOffices',
      fetchTopupTypes: 'topup_types/fetchTopupTypes',
    }),
    formatter() {
      return new Intl.NumberFormat(this.formatLang, {
        style: this.formatStyle,
        currency: this.formatCurrency,
      });
    },
    loadOffices({ ...params } = {}) {
      return this.fetchOffices({
        ...params,
        'filter[office_category_id]': '4',
        // 'filter[search]': this.search.stockist,
        'page[limit]': 5,
        'fields[simple-offices]': 'office,code',
        'fields[offices]':
          'phone,area,address,email,bank_account_number,code,name,order_balance_available,balance',
        include: 'office',
      });
    },
    openModalStockist() {
      this.openListStockist = !this.openListStockist;
      this.loadOffices();
    },
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    debounceSearch: debounce(function () {
      this.loadOffices();
    }, 300),
    onPageChange(page) {
      this.loadOffices(page);
    },
    changeStockist(data) {
      this.form.code = data.attributes.code;
      this.searchStockist();
      this.openListStockist = false;
    },
    searchStockist() {
      if (this.form.code) {
        this.fetchOffices({
          'filter[office_category_id]': '4',
          'filter[code]': this.form.code,
          'fields[offices]': 'code,name',
        }).then(() => {
          if (this.getOffices.data.length > 0) {
            this.form.id = this.getOffices.data[0].attributes.office_id;
            this.form.code = this.getOffices.data[0].attributes.code;
            this.form.name = this.getOffices.data[0].attributes.name;
          } else {
            this.form.id = null;
            this.form.code = null;
            this.form.name = null;
            this.form.deposit_topup = null;
          }
          console.log(this.form.code);
        });
      }
    },
    saveForm() {
      const payload = {
        id: this.form.id,
        data: {
          deposit_topup: parseInt(this.form.deposit_topup),
          topup_type: this.form.topupType.attributes.name,
        },
      };
      this.topupStockistCenter(payload).then((response) => {
        if (response) {
          this.success = true;
        } else {
          this.failed = true;
        }
      });
    },
    clearData() {
      this.form = {
        date: dayjs().format('YYYY-MM-DD'),
        id: null,
        name: null,
        deposit_topup: null,
      };
    },
    onChangeNominalPembayaran(e) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.form.deposit_topup = value;
      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
  },
};
</script>
