<template>
    <datatable :footer="false" :columns="columns" :data="getOrderPaymentHistory" :total="getOrderPaymentHistory.length"
        :paginated="false" empty-message="Kosong">
        <template #tbody>
            <tbody v-if="getOrderPaymentHistory.length < 1">
                <tr class="bg-white">
                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500" :colspan="columns.length">
                        Kosong
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <template v-for="(item, index) in getOrderPaymentHistory">
                    <tr class="bg-white" :key="item.id">
                        <td v-for="column in columns" :key="column.id" :class="[
                            'whitespace-nowrap px-6 py-4 text-sm text-gray-500',
                            column.tbodyClass,
                        ]">
                            <span v-if="item.type === 'payments' && column.id === 'collapse'
                                ">
                                <button class="flex items-center justify-center" @click="handleCollapse(index)">
                                    <svg v-if="collapsedRowsIndex.includes(index)" xmlns="http://www.w3.org/2000/svg"
                                        class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                        stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                            </span>
                            <span v-else>{{
                                column.value
                                ? typeof column.value === 'function'
                                    ? column.value.call(null, item, index)
                                    : column.value
                                : item[column.id]
                            }}</span>
                        </td>
                    </tr>
                    <template v-if="collapsedRowsIndex.includes(index)">
                        <tr v-for="paymentMethod in item.payment_methods" :key="`payment-method-${paymentMethod.id}`"
                            class="bg-gray-50">
                            <td colspan="3"></td>
                            <td :class="[
                                'whitespace-nowrap px-6 py-4 text-sm text-gray-500',
                            ]">
                                {{ parseTypeMethod(paymentMethod) }}
                                {{
                                    paymentMethod.officeBank
                                    ? ` - ${paymentMethod.officeBank}`
                                    : ''
                                }}
                            </td>
                            <td></td>
                            <td :class="[
                                'whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500',
                            ]">
                                <span v-if="paymentMethod.balance_used === 'order_balance'">({{ paymentMethod.amount | toCurrency }})</span>
                                <span v-else>
                                    {{ paymentMethod.amount | toCurrency }}
                                </span>
                            </td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </template>
        <template #tfoot>
            <tfoot>
                <tr :class="[
                    getOrderPaymentHistory.data?.length % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                ]">
                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500" colspan="4">
                        Total
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                        {{
                            +getOrderPaymentReportMeta.total_order_final_price
                            | toCurrency
                        }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                        {{
                            +getOrderPaymentReportMeta.grand_total_payment_amount
                            | toCurrency
                        }}
                    </td>
                    <td colspan="2"></td>
                </tr>
            </tfoot>
        </template>
    </datatable>
</template>
  
<script>
import Datatable from '@/components/base/Datatable.vue';
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/services/date.service';

export default {
    components: {
        Datatable,
    },
    data() {
        return {
            collapsedRowsIndex: [],
        };
    },
    computed: {
        ...mapGetters({
            getOrderPaymentReportMeta: 'offices/getOrderPaymentReportMeta',
            getOrderPaymentHistory: 'offices/getOrderPaymentHistory',
        }),
        columns() {
            return [
                {
                    id: 'createdAt',
                    name: 'Tanggal',
                    value: (item) => formatDate(item.created_at, 'DD/MM/YYYY'),
                },
                {
                    id: 'order_code',
                    name: 'No Faktur',
                    value: (item) => {
                        return item.type === 'balance'
                            ? ''
                            : `${item.order_origin_code}/${item.order_destination_code}`;
                    },
                },
                {
                    id: 'payment_code',
                    name: 'No Invoice',
                },
                {
                    id: 'description',
                    name: 'Keterangan',
                },
                {
                    id: 'final_price',
                    name: 'Belanja',
                    value: (item) => {
                        return item.type === 'orders'
                            ? `-${this.$options.filters.toCurrency(+item.order_final_price)}`
                            : '';
                    },
                    theadClass: 'text-right',
                    tbodyClass: 'text-right',
                },
                {
                    id: 'payment_amount',
                    name: 'Pembayaran',
                    value: (item, index) => {
                        const paymentAmount = this.$options.filters.toCurrency(
                            +item.payment_amount
                        );

                        if (item.type === 'balance') {
                            return `+${paymentAmount}`;
                        }

                        if (
                            item.type === 'payments' &&
                            !this.collapsedRowsIndex.includes(index)
                        ) {
                            return `+${paymentAmount}`;
                        }

                        return '';
                    },
                    theadClass: 'text-right',
                    tbodyClass: 'text-right',
                },
                {
                    id: 'balance',
                    name: 'Saldo',
                    value: (item) => {
                        return this.$options.filters.toCurrency(item.balance);
                    },
                    theadClass: 'text-right',
                    tbodyClass: 'text-right',
                },
                {
                    id: 'collapse',
                    name: '',
                },
            ];
        },
    },
    methods: {
        ...mapActions({
            fetchOrderPaymentReportMeta: 'offices/fetchOrderPaymentReportMeta',
            fetchOrderPaymentHistory: 'offices/fetchOrderPaymentHistory',
        }),
        handleChangePage(page) {
            // this.page.number = page;

            // this.loadData();
            page
        },
        handleCollapse(rowIndex) {
            const index = this.collapsedRowsIndex.findIndex(
                (item) => item === rowIndex
            );

            if (index === -1) {
                this.collapsedRowsIndex.push(rowIndex);
            } else {
                this.collapsedRowsIndex.splice(index, 1);
            }
        },
        parseTypeMethod(method) {
            if (method.type) {
                return method.type;
            }

            return {
                order_balance: 'Saldo Pembayaran',
                cashback: 'Cashback'
            }[method.balance_used];
        }
    },
};
</script>
  