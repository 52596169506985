<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard class="space-y-4" title="Data Pembayaran Stockist">
      <div class="flex items-end justify-end space-x-2">
        <div class="relative mx-2 w-2/12 rounded-md shadow-sm">
          <label
            for="kantor_pembuat"
            class="mb-2 block text-sm font-medium text-gray-700"
            >Asal Faktur</label
          >
          <base-select
            id="order_type"
            v-model="filter.byOrderType"
            @change="handleChangeFilter"
          >
            <option :value="null">Semua</option>
            <option value="sale">Pusat dan KP</option>
            <option value="purchase">Stockist</option>
          </base-select>
        </div>
        <div class="relative mx-2 w-2/12 rounded-md shadow-sm">
          <label
            for="kantor_pembuat"
            class="mb-2 block text-sm font-medium text-gray-700"
            >Status</label
          >
          <base-select v-model="is_paid" @change="filterByPaid">
            <option :value="null">Semua</option>
            <option :value="true">Lunas</option>
            <option :value="false">Belum Lunas</option>
          </base-select>
        </div>
        <base-search v-model="search" @input="debounceSearch" />
      </div>
      <div class="my-2 flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <KasirTable
            view-as="pusat"
            @page-change="onPageChangeOrder"
            @click-row="redirectToDetail"
          />
        </div>
      </div>
    </BaseCard>
    <div v-if="isLoading" class="flex items-center text-center">
      <loading></loading>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import BaseSearch from '@/components/base/Search.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import KasirTable from '@/components/kasir/kasir-table.vue';

export default {
  name: 'IndexPembayaran',
  components: {
    BaseSearch,
    BaseCard,
    KasirTable,
  },

  data() {
    return {
      search: null,
      openDetail: false,
      is_paid: false,
      warehouse_status: null,
      data: {},
      filter: {
        byOrderType: 'sale',
      },
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
    orderParams() {
      return {
        'filter[not-order_status]': !this.is_paid ? 'Draft' : undefined,
        'page[limit]': 5,
        fields: {
          // 'simple-orders': 'origin_code,warehouse_code',
          orders:
            'origin_code,destination-office,is_valid_for_packing,is_paid,destination_code,updatedAt,origin_warehouse_code,final_price,grand_total_price,cashback,payment_amount,canceled_by_user_id,canceled_from,order_status,area_name,order-details,grand_total_weight,origin-warehouse',
          offices: 'code,name,address,address2',
          warehouses: 'code',
        },
        include: 'destination-office,order-details,origin-warehouse,order',
        'filter[destination_office_category_id]': 4,
        'filter[origin_office_category_ids]': '1,2',
        'filter[search]': this.search ?? undefined,
        'filter[is_paid]': this.is_paid ?? undefined,
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      updateOrder: 'orders/updateOrder',
    }),
    handleChangeFilter() {
      this.fetchOrders({
        ...this.orderParams,
      });
    },
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.orderParams,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...this.orderParams,
        ...page,
      });
    },
    filterByPaid() {
      this.fetchOrders({
        ...this.orderParams,
      });
    },
    cancelOrder(data) {
      if (data.attributes.payment_amount <= 0) {
        const payload = {
          data: {
            type: 'orders',
            id: data.id,
            attributes: {
              is_valid_for_payment: false,
            },
          },
        };
        this.updateOrder(payload).then((response) => {
          if (response) {
            this.openDetail = false;
            this.fetchOrders({
              ...this.orderParams,
            });
          }
        });
      }
    },
    redirectToDetail(data) {
      this.$router.push(
        `faktur-penjualan-pusat-ke-stockist/${data.attributes.order_id}`
      );
    },
  },
  created() {
    this.fetchOrders({
      ...this.orderParams,
    });
  },
};
</script>
