<template>
  <div class="mx-auto my-4 px-4 sm:px-4 lg:px-8">
    <div class="space-y-6">
      <div class="flex justify-end gap-x-2">
        <period-select
          v-model="filter.period_uuid"
          v-on:change="handleFilter"
        />
      </div>
      <datatable
        :columns="columns"
        :data="getOrderPaymentReports.data.data"
        :total="getOrderPaymentReports.data.total"
        :per-page="page.size"
        :current-page="page.number"
        empty-message="Kosong"
        v-on:pagechanged="handleChangePage"
      >
        <template #tfoot>
          <tfoot>
            <tr
              :class="[
                getOrderPaymentReports.data.data.lengt % 2 === 0
                  ? 'bg-gray-50'
                  : 'bg-white',
              ]"
            >
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                colspan="2"
              >
                Total
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                {{
                  +getOrderPaymentReports.meta.total_order_final_price
                    | toCurrency
                }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                {{
                  +getOrderPaymentReports.meta.total_payment_amount | toCurrency
                }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                {{
                  +getOrderPaymentReports.meta.total_office_balance | toCurrency
                }}
              </td>
            </tr>
          </tfoot>
        </template>
      </datatable>
    </div>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import Datatable from '@/components/base/Datatable.vue';
import PeriodSelect from '@/components/period/PeriodSelect.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    PeriodSelect,
    Datatable,
  },
  data() {
    return {
      filter: {
        is_point: null,
        period_uuid: null,
      },
      page: {
        size: 10,
        number: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      getLoading: 'offices/getLoading',
      getOrderPaymentReports: 'offices/getOrderPaymentReports',
    }),
    isLoading() {
      return this.getLoading;
    },
    columns() {
      return [
        {
          id: 'office_code',
          name: 'Stockist',
        },
        {
          id: 'office_name',
          name: 'Pengelola Stockist',
        },
        {
          id: 'sum_order_final_price',
          name: 'Belanja',
          value: (item) =>
            item.sum_order_final_price
              ? this.$options.filters.toCurrency(+item.sum_order_final_price)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'sum_payment_amount',
          name: 'Pembayaran',
          value: (item) =>
            item.sum_payment_amount
              ? this.$options.filters.toCurrency(+item.sum_payment_amount)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
        {
          id: 'office_balance',
          name: 'Saldo',
          value: (item) =>
            item.office_balance
              ? this.$options.filters.toCurrency(+item.office_balance)
              : 0,
          theadClass: 'text-right',
          tbodyClass: 'text-right',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchOrderPaymentReports: 'offices/fetchOrderPaymentReports',
      fetchPeriods: 'periods/fetchPeriods',
    }),
    handleFilter() {
      this.loadData({ resetPage: true });
    },
    handleChangePage(page) {
      this.page.number = page;

      this.loadData();
    },
    loadData({ resetPage } = {}) {
      if (resetPage) {
        this.page.number = 1;
      }

      this.fetchOrderPaymentReports({
        'page[size]': this.page.size,
        'page[number]': this.page.number,
        'filter[period_uuid]': this.filter.period_uuid,
      });
    },
  },
  created() {
    this.fetchPeriods({
      'filter[year]': new Date().getFullYear(),
    }).then((response) => {
      this.filter.period_uuid = response.data.data[new Date().getMonth()]?.id;
      this.loadData();
    });
  },
};
</script>
