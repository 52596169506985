<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-4">
        <base-checkbox
          class="hidden"
          label="Hanya yang memiliki laporan"
          v-model="getOfficesRevenueParams.filter.has_order_bundles"
          v-on:change="handleFilter"
        />
        <base-select
          :options="monthOptions"
          :expand="false"
          v-model="getOfficesRevenueParams.filter.month"
          v-on:change="handleFilter"
        />
        <base-select
          :options="yearOptions"
          :expand="false"
          v-model="getOfficesRevenueParams.filter.year"
          v-on:change="handleFilter"
        />
        <base-input
          type="text"
          :with-label="false"
          placeholder="Cari Stockist Center"
          debounce
          v-model="getOfficesRevenueParams.filter.search"
          v-on:native-input="handleFilter"
        />
      </div>
      <Datatable
        :total="getOfficesRevenue.total"
        :perPage="getOfficesRevenue.per_page"
        :currentPage="getOfficesRevenue.current_page"
        @pagechanged="onPageChange"
      >
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Stockist Center
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Kode SC
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Omset SC
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <div v-if="getLoading" class="flex items-center text-center">
            <loading></loading>
          </div>
          <tbody v-if="getOfficesRevenue.data.length > 0">
            <tr
              class="bg-white hover:bg-green-100"
              v-for="(data, dataIdx) in getOfficesRevenue.data"
              :key="data.id"
              :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ data.name }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ data.code }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                {{ +data.order_bundles_sum_bundle_report_price | toCurrency }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="bg-white">
              <td
                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                colspan="8"
              >
                Data tidak ditemukan
              </td>
            </tr>
          </tbody>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';
import BaseInput from '@/components/base/BaseInput';
import BaseCheckbox from '@/components/base/BaseCheckbox';
import BaseSelect from '@/components/base/BaseSelect';
import { getMonthNames, getCurrent } from '@/utils/date';
import dayjs from 'dayjs';

export default {
  components: { Datatable, BaseCheckbox, BaseInput, BaseSelect },
  data: function () {
    return {
      getOfficesRevenueParams: {
        filter: {
          has_order_bundles: 0,
          search: null,
          year: getCurrent('year'),
          month: getCurrent('month'),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getOfficesRevenue: 'offices/getOfficesRevenue',
      getLoading: 'offices/getLoading',
    }),
    monthOptions: function () {
      return getMonthNames().map((month, index) => ({
        key: index,
        label: month,
        value: index,
      }));
    },
    yearOptions: function () {
      return Array(10)
        .fill(getCurrent('year'))
        .map((year, index) => ({
          key: year - index,
          label: year - index,
          value: year - index,
        }));
    },
    selectedFilterFromToDate: function () {
      const date = dayjs()
        .set('month', this.getOfficesRevenueParams.filter.month)
        .set('year', this.getOfficesRevenueParams.filter.year);

      return {
        from_date: date.startOf('month').format(),
        to_date: date.endOf('month').format(),
      };
    },
  },
  methods: {
    ...mapActions({
      fetchOfficesRevenue: 'offices/fetchOfficesRevenue',
    }),
    loadOfficesRevenue: function ({ pageNumber } = {}) {
      this.fetchOfficesRevenue({
        'filter[office_type]': 'stockist_center',
        'filter[search]': this.getOfficesRevenueParams.filter.search,
        'filter[from_date]': this.selectedFilterFromToDate.from_date,
        'filter[to_date]': this.selectedFilterFromToDate.to_date,
        ...(this.getOfficesRevenueParams.filter.has_order_bundles
          ? {
              'filter[has_order_bundles]': 1,
            }
          : {}),
        'page[number]': pageNumber,
      });
    },
    handleFilter: function () {
      this.loadOfficesRevenue({ pageNumber: 1 });
    },
    onPageChange: function (page) {
      this.loadOfficesRevenue({ pageNumber: page });
    },
  },
  created: function () {
    this.loadOfficesRevenue();
  },
};
</script>
