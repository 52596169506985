<template>
  <div class="w-full space-y-4 px-4 py-8 sm:px-6 lg:px-8">
    <!-- Replace with your content -->

    <!-- /End replace -->
  </div>
</template>
<script>
export default {
  name: 'Index',
  components: {},
  data() {
    return {
      dataProp: [
        {
          title: 'Total Penjualan',
          value: '20',
          icon: 'uil:shopping-basket',
        },
        {
          title: 'Total Pendapatan',
          value: 'Rp. 5.000.000',
          icon: 'uil:bill',
        },
        {
          title: 'Total Transaksi',
          value: 'Rp. 4.500.000',
          icon: 'iconTransaksi',
        },
        {
          title: 'Total Produk',
          value: '40',
          icon: 'iconProduk',
        },
      ],
    };
  },
  methods: {
    //
  },
  mounted() {
    //
  },
};
</script>
