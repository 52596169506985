<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <!-- <section class="my-6">
        <div class="mb-4">
          <h3 class="text-lg leading-6 font-bold text-gray-900">
            Rangkuman
          </h3>
        </div>
        <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          <div class="bg-white overflow-hidden shadow rounded-lg" v-for="item in datas" :key="item.name">
            <div class="relative p-4">
              <div class="flex items-center">
                <div class="w-0 flex-1">
                  <div>
                    <div class="flex justify-between items-center md:block lg:flex">
                        <div class="text-sm font-medium text-gray-900 truncate">
                          {{item.title}}
                        </div>
                        <div class="text-sm font-bold text-gray-700 truncate">
                          <button class="hover:bg-gray-100 rounded p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
                            </svg>
                          </button>
                        </div>
                    </div>
                    <div class="mt-6">
                      <div class="text-lg font-bold text-gray-900">
                        {{item.total}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="absolute -bottom-4 -right-3 flex-shrink-0 text-gray-200">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
      <section>
        <div
          class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
        >
          <h3 class="text-lg font-bold leading-6 text-gray-900">Laporan</h3>
          <div class="flex mt-3 sm:mt-0 sm:ml-4">
            <router-link
              to="laporan/tambah-laporan"
              type="button"
              class="focus:outline-none inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              <!-- Heroicon name: solid/mail -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              Tambah Laporan
            </router-link>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex justify-end">
            <base-search
              v-model="search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <Datatable
                :total="getOrderBundles.meta.page.total"
                :perPage="getOrderBundles.meta.page.perPage"
                :currentPage="getOrderBundles.meta.page.currentPage"
                @pagechanged="onPageChange"
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor Laporan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Deskripsi
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor PO
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Total Produk
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Tanggal Laporan
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <tbody>
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getOrderBundles.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click="tableDetails(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ data.attributes.code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ data.attributes.description }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        -
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        -
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ dayjs(data.attributes.createdAt).format('ll LT') }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>
        </div>
      </section>

      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="openDetail"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
            >
              <div class="flex mb-8 justify-between">
                <div>
                  <h3 class="text-lg font-bold leading-6 text-gray-900">
                    Detail Laporan
                  </h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Data laporan yang telah dibuat
                  </p>
                </div>
                <div>
                  <img
                    src="@/assets/images/logo.png"
                    alt=""
                    class="flex justify-end"
                  />
                </div>
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">No Laporan</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.code }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Tanggal Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dayjs(dataLaporan.attributes.updatedAt).format('ll LT')
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Deskripsi</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.description }}
                    </dd>
                  </div>
                </dl>
                <dl class="flex mt-6 flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Nama Stockist
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <!-- {{dataLaporan.relationships['destination-office'].data ? getRelationships(dataLaporan.relationships['destination-office'].data.id).attributes.name : '-'}} -->
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Alamat Stockist 1
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <!-- {{dataLaporan.relationships['destination-office'].data ? getRelationships(dataLaporan.relationships['destination-office'].data.id).attributes.address : '-'}} -->
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Alamat Stockist 2
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <!-- {{dataLaporan.relationships['destination-office'].data ? getRelationships(dataLaporan.relationships['destination-office'].data.id).attributes.address2 : '-'}} -->
                    </dd>
                  </div>
                </dl>
                <div class="my-6 sm:col-span-6">
                  <dt class="text-sm font-bold text-gray-700">Daftar Barang</dt>
                  <!-- <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            KODE BARANG
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider pr-12">
                            JUMLAH
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider pr-12">
                            BERAT
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider pr-12">
                            TOTAL HARGA
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider pr-12">
                            TOTAL BERAT
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr class="bg-white" v-for="(barang, index) in dataLaporan.attributes.order_details" :key="index">
                          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {{barang.product_code}}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right pr-12">
                            {{barang.product_qty}}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right pr-12">
                            {{barang.product_weight}}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right pr-12">
                            {{barang.total_price}}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right pr-12">
                            {{barang.total_weight}}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable> -->
                </div>
                <div class="flex">
                  <!-- <div class="flex flex-col w-2/3 gap-y-2">
                    <span v-if="dataLaporan.is_completed" class="inline-flex w-24 justify-center items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                      Selesai
                    </span>
                    <span v-else class="inline-flex w-36 justify-center text-center items-center px-3 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
                      Belum Selesai
                    </span>
                  </div>
                  <div class="flex flex-col w-1/3 gap-y-2">
                    <div class="inline-flex justify-between w-full">
                      <div class="sm:col-span-1">
                        <label for="invoice" class="block text-sm font-medium text-gray-700">
                          Total harga keseluruhan
                        </label>
                      </div>
                      <div class="sm:col-span-1">
                        <label for="invoice" class="block text-sm font-bold text-gray-900">
                          {{dataLaporan.attributes.final_price}}
                        </label>
                      </div> 
                    </div>
                    <div class="inline-flex justify-between w-full">
                      <div class="sm:col-span-1">
                        <label for="invoice" class="block text-sm font-medium text-gray-700">
                          Total berat keseluruhan
                        </label>
                      </div>
                      <div class="sm:col-span-1">
                        <label for="invoice" class="block text-sm font-bold text-gray-900">
                          {{dataLaporan.attributes.grand_total_weight}} Kg
                        </label>
                      </div> 
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="flex justify-end">
                <button
                  @click="openDetail = !openDetail"
                  type="button"
                  class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import BaseSearch from '@/components/base/Search.vue';
import dayjs from 'dayjs';

export default {
  name: 'Home',
  components: {
    Datatable,
    BaseSearch,
  },

  data() {
    return {
      search: null,
      datas: [
        {
          title: 'Penjualan Terlapor',
          total: '15',
          svg: '',
        },
        {
          title: 'Penjualan Belum Terlapor',
          total: '5',
          svg: '',
        },
      ],
      openDetail: false,
      dataLaporan: {},
    };
  },

  computed: {
    ...mapGetters({
      getOrderBundles: 'order_bundles/getOrderBundles',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrderBundles: 'order_bundles/fetchOrderBundles',
    }),
    debounceSearch: debounce(function () {
      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
      });
    }, 300),
    onPageChange(page) {
      this.fetchOrderBundles({
        pageNumber: page,
        pageSize: 5,
        search: this.search,
      });
    },
    tableDetails(data) {
      this.openDetail = !this.openDetail;
      this.dataLaporan = data;
    },
  },
  created() {
    this.fetchOrderBundles({
      pageNumber: 1,
      pageSize: 5,
    });
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
