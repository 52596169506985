<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard :title="`Riwayat ${title}`">
      <template #action>
        <base-button to="saldo-stockist/tambah" type="button" id="create">
          <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
          {{ title }}
        </base-button>
      </template>
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <stockist-select-search
            placeholder="Cari Kode Stockist"
            v-model="filter.office"
            v-on:change="handleChangeFilter"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getBalance.meta.page.total"
              :perPage="getBalance.meta.page.perPage"
              :currentPage="getBalance.meta.page.currentPage"
              @pagechanged="onPageChangeBalance"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Stockist
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Tanggal topup
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Jumlah Deposit
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <div v-if="isLoading" class="flex items-center text-center">
                  <loading></loading>
                </div>
                <tbody v-if="getBalance.data?.length">
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getBalance.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="tableDetails(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        getRelationships(data.attributes.office_uuid).attributes
                          .code
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ dayjs(data.attributes.createdAt).format('ll LT') }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                    >
                      {{ data.attributes.balance_amount | toCurrency }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="bg-white">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      colspan="8"
                    >
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import StockistSelectSearch from '@/components/stockist/stockist-select-search.vue';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export default {
  name: 'Home',
  components: {
    Datatable,
    StockistSelectSearch,
  },
  props: {
    title: {
      type: String,
      default: 'Topup Saldo Stockist',
    },
  },
  data() {
    return {
      search: null,
      openDetail: false,
      is_paid: false,
      data: {},
      filter: {
        office: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getBalance: 'balance_trxes/getData',
      isLoading: 'balance_trxes/getLoading',
    }),
    balanceTrxesParams: function () {
      return {
        trxType: 'topup_stockist',
        'page[number]': 1,
        'page[size]': 5,
        office_uuid: this.filter.office ? this.filter.office.id : null,
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOffices: 'offices/fetchOffices',
      fetchSaldoHistory: 'offices/saldoHistory',
      fetchBalanceTrxes: 'balance_trxes/fetchBalanceTrxes',
    }),
    debounceSearch: debounce(function () {
      this.fetchBalanceTrxes(this.balanceTrxesParams);
    }, 300),
    onPageChangeBalance(page) {
      this.fetchBalanceTrxes({
        ...this.balanceTrxesParams,
        pageNumber: page,
      });
    },
    tableDetails(data) {
      this.openDetail = !this.openDetail;
      this.data = data;
    },
    getRelationships(id) {
      let data = this.getBalance.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    handleChangeFilter: function () {
      this.fetchBalanceTrxes(this.balanceTrxesParams);
    },
  },
  created() {
    this.fetchBalanceTrxes(this.balanceTrxesParams);
  },
};
</script>
