var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex items-center justify-end gap-x-2"},[_c('base-button',{attrs:{"to":{ name: 'kasir.topup.saldo-sisa-pembayaran-sc.tambah' }}},[_vm._v("Topup Saldo Sisa Pembayaran SC")])],1),_c('div',[_c('datatable',{attrs:{"columns":_vm.columns,"data":_vm.balanceTrxes.data,"scroll-x":false,"total":_vm.balanceTrxes.meta.page.total,"perPage":_vm.balanceTrxes.meta.page.perPage,"currentPage":_vm.balanceTrxes.meta.page.currentPage},on:{"pagechanged":_vm.onPageChanged},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.balanceTrxes.data),function(balanceTrx,index){return _c('tr',{key:balanceTrx.id,class:[
              classes.tr,
              index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
              'bg-white hover:bg-green-100',
            ]},[_c('td',{class:[classes.td]},[_c('span',{staticClass:"font-bold text-gray-900"},[_vm._v(_vm._s(_vm.getSingleIncluded( _vm.balanceTrxes, balanceTrx.relationships.office.data.id ).attributes.code))])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm._f("formatDate")(balanceTrx.attributes.createdAt))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(balanceTrx.attributes.overpayment_amount))+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{class:{
                  'cursor-pointer': !balanceTrx.attributes.is_approved,
                },attrs:{"color":balanceTrx.attributes.is_approved ? 'green' : 'red'},on:{"click":function($event){return _vm.onApprove(balanceTrx)}}},[_vm._v(" "+_vm._s(balanceTrx.attributes.is_approved ? 'Valid' : 'Belum Valid')+" ")])],1)])})}}])})],1)]),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }