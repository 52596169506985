<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="space-y-4">
      <div class="flex justify-end gap-x-2">
        <period-select
          v-model="filter.period_uuid"
          v-on:change="handleFilter"
        />
        <base-button
          class="flex"
          :disabled="getLoadingActions('downloadDbf')"
          v-on:click="handleDownload"
        >
          <base-spinner
            color="white"
            v-if="getLoadingActions('downloadDbf')"
          ></base-spinner>
          <Icon class="h-5 w-5" icon="heroicons:arrow-down-tray-20-solid" />
          Download GL
        </base-button>
      </div>
      <Datatable
        :total="getJournalEntries.meta.page.total"
        :perPage="getJournalEntries.meta.page.perPage"
        :currentPage="getJournalEntries.meta.page.currentPage"
        @pagechanged="onPageChange"
      >
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                v-for="item in columns"
                :key="item.value"
                scope="col"
                :class="item.class"
                class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                @click="handleSort(item.value)"
              >
                <div class="flex items-center">
                  {{ item.label }}
                  <Icon
                    :class="{ 'rotate-180': query.sort.includes(item.value) }"
                    class="h-4 w-4"
                    icon="heroicons:chevron-down-20-solid"
                  />
                </div>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <div
            v-if="getLoadingActions('fetchAllJournalEntries')"
            class="flex items-center text-center"
          >
            <loading></loading>
          </div>
          <tbody v-if="getJournalEntries.data.length > 0">
            <tr
              class="bg-white hover:bg-green-100"
              v-for="(data, dataIdx) in getJournalEntries.data"
              :key="data.id"
              :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ data.attributes.payment_code }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ data.attributes.order_origin_code }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ data.attributes.createdAt | formatDate }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ data.attributes.accno }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ data.attributes.cost_center }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span v-if="data.attributes.debit_amount">{{
                  data.attributes.debit_amount | toCurrency
                }}</span>
                <span v-else>-</span>
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span v-if="data.attributes.credit_amount">{{
                  data.attributes.credit_amount | toCurrency
                }}</span>
                <span v-else>-</span>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ data.attributes.description || '-' }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ data.attributes.angsuran || '-' }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="bg-white">
              <td
                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                colspan="8"
              >
                Data tidak ditemukan
              </td>
            </tr>
          </tbody>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { downloadFile } from '@/services/utils.service.js';
import Datatable from '@/components/base/Datatable';
import BaseButton from '@/components/base/BaseButton';
import BaseSpinner from '@/components/base/BaseSpinner';
import PeriodSelect from '@/components/period/PeriodSelect.vue';
import dayjs from 'dayjs';

export default {
  components: { Datatable, BaseButton, BaseSpinner, PeriodSelect },
  computed: {
    ...mapGetters({
      getPeriod: 'periods/getPeriod',
      getJournalEntries: 'journal_entries/getJournalEntries',
      getLoadingActions: 'journal_entries/getLoadingActions',
    }),
  },
  data() {
    return {
      query: {
        sort: 'native-id',
        pageNumber: 1,
      },
      filter: {
        period_uuid: null,
      },
      columns: [
        {
          label: 'NO',
          value: 'payment_code',
        },
        {
          label: 'VOURCHERNO',
          value: 'order_origin_code',
        },
        {
          label: 'DATE',
          value: 'createdAt',
        },
        {
          label: 'ACCNO',
          value: 'accno',
        },
        {
          label: 'COSTCENTRE',
          value: 'cost_center',
        },
        {
          label: 'DBAMOUNT',
          value: 'debit_amount',
          class: 'text-right',
        },
        {
          label: 'CRAMOUNT',
          value: 'credit_amount',
          class: 'text-right',
        },
        {
          label: 'DESCRIPTION',
          value: 'description',
        },
        {
          label: 'ANGSUR',
          value: 'angsuran',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      downloadDbf: 'journal_entries/downloadDbf',
      fetchAllJournalEntries: 'journal_entries/fetchAllJournalEntries',
      fetchPeriods: 'periods/fetchPeriods',
      fetchCurrentPeriod: 'periods/fetchCurrentPeriod',
    }),
    handleFilter() {
      this.loadJournalEntris();
    },
    handleSort(value) {
      if (this.query.sort[0] != '-') {
        this.query.sort = value;
      }

      this.loadJournalEntris();
    },
    loadJournalEntris: function ({ toggleSort } = { toggleSort: true }) {
      const params = {
        'page[size]': 10,
        'page[number]': this.query.pageNumber,
        sort: toggleSort ? null : this.query.sort,
        'filter[period_uuid]': this.filter.period_uuid ?? undefined,
      };

      if (toggleSort) {
        if (this.query.sort[0] != '-') {
          params.sort = `-${this.query.sort}`;
          this.query.sort = `-${this.query.sort}`;
        } else {
          // remove first letter
          const str = this.query.sort.slice(1, this.query.sort.length);
          params.sort = str;
          this.query.sort = str;
        }
      }

      this.fetchAllJournalEntries(params);
    },
    handleDownload: function () {
      this.downloadDbf().then((res) => {
        downloadFile(res.data, `bonus-${dayjs().format('YYYY-MM-DD')}.dbf`);
      });
      this.downloadDbf({ action: 'downloadMasterDbf' }).then((res) => {
        downloadFile(res.data, `master-${dayjs().format('YYYY-MM-DD')}.dbf`);
      });
    },
    onPageChange: function (page) {
      this.query.pageNumber = page;
      this.loadJournalEntris({ toggleSort: false });
    },
    async setCurrentPeriod() {
      await this.fetchPeriods({
        'filter[year]': new Date().getFullYear(),
      });
      await this.fetchCurrentPeriod();

      this.filter.period_uuid = this.getPeriod.data.id;
    },
  },
  created: async function () {
    await this.setCurrentPeriod();
    this.loadJournalEntris();
  },
};
</script>
