<template>
  <base-wrapper class="space-y-4">
    <base-card title="Tambah Saldo Saldo Sisa Pembayaran SC">
      <form class="grid gap-4 sm:grid-cols-2" @submit.prevent="onSubmit">
        <div class="sm:col-span-2">
          <base-input inset with-label label="Tanggal" disabled :value="date" />
        </div>
        <div>
          <search-office-input v-model="office" />
        </div>
        <div>
          <base-input
            inset
            with-label
            label="Nama Stockist Center"
            placeholder="SC1"
            disabled
            :value="office.name"
          />
        </div>
        <div class="sm:col-span-2">
          <base-input
            inset
            with-label
            label="Nominal Topup"
            placeholder="100.000"
            type="text"
            currency
            v-model="amount"
          />
        </div>
        <div class="flex items-center gap-2 sm:col-span-2">
          <base-button :disabled="disabled">Simpan</base-button>
          <base-button
            type="button"
            color="white"
            :to="{ name: 'kasir.topup.saldo-sisa-pembayaran-sc' }"
            >Kembali</base-button
          >
        </div>
      </form>

      <loading v-if="loading" />
    </base-card>
  </base-wrapper>
</template>

<script>
import dayjs from 'dayjs';
import SearchOfficeInput from '@/components/office/search-office-input.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  components: { SearchOfficeInput },
  mixins: [requestMixin],
  data() {
    return {
      amount: null,
      date: dayjs().format('YYYY-MM-DD'),
      office: {
        id: null,
        code: null,
        originalCode: null,
        name: null,
      },
      loading: false,
    };
  },
  computed: {
    disabled() {
      return !this.office.id || !this.amount;
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onSubmit() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/offices/${this.office.id}/-actions/top-up`,
        {
          method: 'post',
          data: {
            trx_type: 'overpayment_balance',
            amount: Number(this.amount.replace(/\D/gi, '')),
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.$router.push({ name: 'kasir.topup.saldo-sisa-pembayaran-sc' });
      }

      this.loading = false;
    },
  },
};
</script>
