<template>
  <BaseCard id="laporan-penjualan-pusat" class="my-10 space-y-4 px-8">
    <!-- Filter -->
    <div class="flex items-center justify-end gap-x-2">
      <base-input
        type="date"
        label="Dari"
        :max="filter.to_date"
        with-label
        v-model="filter.from_date"
        v-on:change="handleChangeFilterDate"
      />
      <base-input
        type="date"
        label="Sampai"
        :min="filter.from_date"
        with-label
        v-model="filter.to_date"
        v-on:change="handleChangeFilterDate"
      />
      <base-input class="w-2/12" label="Metode Pembayaran" with-label>
        <base-select
          :options="filterMethodOptions"
          v-model="filter.payment_method_type_uuid"
          v-on:change="handleChangeFilterMethod"
        />
      </base-input>
      <base-input
        v-if="isFilterUsingBank"
        class="w-2/12"
        label="Bank"
        with-label
      >
        <base-select
          :options="bankSelectOptions"
          v-model="filter.office_bank_uuid"
          v-on:change="handleChangeFilter"
        />
      </base-input>
      <base-input label="Pilih Kantor" with-label>
        <office-select-search
          :filter="{
            office_type: 'stockist',
          }"
          with-name
          v-model="filter.office"
          v-on:change="handleChangeFilter"
        />
      </base-input>
      <base-button
        :disabled="isLoadingDailyReport"
        v-on:click="handleClickReport"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="h-5 w-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          ></path>
        </svg>
      </base-button>
    </div>

    <!-- Table -->
    <datatable
      :total="getPayments.meta.page.total"
      :perPage="getPayments.meta.page.perPage"
      :currentPage="getPayments.meta.page.currentPage"
      @pagechanged="handleChangePage"
    >
      <template v-slot:thead>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Nomor Invoice
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Faktur
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Tanggal
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Stockist
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Total Jual
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Jumlah
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Keterangan
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            ></th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <div
          v-if="isLoading || isLoadingPaymentMethods || isLoadingReportMeta"
          class="flex items-center text-center"
        >
          <loading></loading>
        </div>
        <template v-if="getPayments.data.length">
          <tbody>
            <template v-for="(payment, index) in getPayments.data">
              <tr
                class="bg-white hover:bg-green-100"
                :key="payment.id"
                :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ payment.attributes.code }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{
                    getSingleIncluded(
                      getPayments,
                      payment.relationships.order.data.id
                    ).attributes.origin_code
                  }}
                  /
                  {{
                    getSingleIncluded(
                      getPayments,
                      payment.relationships.order.data.id
                    ).attributes.destination_code
                  }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ dayjs(payment.attributes.updatedAt).format('L') ?? '-' }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{
                    getSingleIncluded(
                      getPayments,
                      payment.relationships['destination-office'].data.id
                    ).attributes.code
                  }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{
                    getSingleIncluded(
                      getPayments,
                      payment.relationships.order.data.id
                    ).attributes.grand_total_price | toCurrency
                  }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ payment.attributes.total_amount | toCurrency }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ payment.attributes.description ?? '-' }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                >
                  <button
                    class="flex items-center justify-center text-center"
                    @click="handleCollapse(payment.id, payment)"
                  >
                    <svg
                      v-if="collapsedRowsIndex.includes(payment.id)"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
              <template v-if="collapsedRowsIndex.includes(payment.id)">
                <template
                  v-if="
                    !collapsedRowsIndexDetails[payment.id]?.paymentMethods?.data
                      ?.length
                  "
                >
                  <tr :key="`payment-${index}-method-kosong`">
                    <td colspan="3"></td>
                    <td
                      colspan="4"
                      class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
                    >
                      Tidak Ada Metode Pembayaran
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr
                    v-for="(
                      paymentMethod, methodIndex
                    ) in collapsedRowsIndexDetails[payment.id]?.paymentMethods
                      ?.data ?? []"
                    :key="`payment-${index}-method-${methodIndex}`"
                  >
                    <td colspan="3"></td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
                    >
                      {{ paymentMethod.attributes.payment_method_type ?? '-' }}
                      {{
                        checkIsPaymentMethodHasOfficeBank(
                          payment.id,
                          paymentMethod
                        )
                          ? `(${
                              getPaymentMethodOfficeBank(
                                payment.id,
                                paymentMethod
                              ).attributes.bank_name
                            }) (${
                              getPaymentMethodOfficeBank(
                                payment.id,
                                paymentMethod
                              ).attributes.account_number
                            })`
                          : ``
                      }}
                    </td>
                    <td
                      colspan="2"
                      class="whitespace-nowrap px-6 py-4 text-right text-xs text-gray-500"
                    >
                      {{ paymentMethod.attributes.payment_amount | toCurrency }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-xs text-gray-500"
                    >
                      {{ paymentMethod.attributes.description ?? '-' }}
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
          <tbody>
            <tr class="bg-white">
              <td colspan="3"></td>
              <td
                class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
              >
                Nilai Total
              </td>
              <td
                class="space-x-1 whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span>
                  {{ getReportMeta.total_grand_total_price | toCurrency }}
                </span>
              </td>
              <td
                class="space-x-1 whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span>
                  {{ getReportMeta.total_amount | toCurrency }}
                </span>
              </td>
              <td colspan="2"></td>
            </tr>
          </tbody>
        </template>
        <tbody v-else>
          <tr class="bg-white">
            <td
              colspan="8"
              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
            >
              Tidak ada data
            </td>
          </tr>
        </tbody>
      </template>
    </datatable>
  </BaseCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import Datatable from '@/components/base/Datatable.vue';
import OfficeSelectSearch from '@/components/office/office-select-search.vue';
import Loading from '@/components/Loading.vue';
import debounce from 'debounce';
import dayjs from 'dayjs';
import { StorageService } from '@/services/storage.service';
import { downloadFile } from '@/services/utils.service';

export default {
  name: 'LaporanPenjualanHarian',
  components: {
    BaseButton,
    BaseInput,
    BaseSelect,
    Datatable,
    Loading,
    OfficeSelectSearch,
  },
  data: function () {
    return {
      filter: {
        from_date: dayjs().format('YYYY-MM-DD'),
        to_date: dayjs().format('YYYY-MM-DD'),
        payment_method_type_uuid: null,
        office: null,
        office_bank_uuid: null,
      },
      pagination: {
        size: 5,
        number: 1,
      },
      collapsedRowsIndex: [],
      collapsedRowsIndexDetails: {},
    };
  },
  computed: {
    ...mapGetters({
      getPayments: 'payments/getPayments',
      getReportMeta: 'payments/getReportMeta',
      isLoading: 'payments/getLoading',
      isLoadingPaymentMethods: 'payments/getLoadingPaymentMethods',
      isLoadingReportMeta: 'payments/getLoadingReportMeta',
      isLoadingDailyReport: 'payment_methods/getLoadingDailyReport',
      getPaymentMethodTypes: 'payment_method_types/getPaymentMethodTypes',
      getBanksByOffice: 'banks/getBanksByOffice',
      getUser: 'auth/getUser',
    }),
    filterMethodOptions: function () {
      return [
        {
          key: 'all',
          label: 'Semua',
          value: null,
        },
        ...this.getPaymentMethodTypes.data.map((type) => ({
          key: type.id,
          label: type.attributes.name,
          value: type.id,
        })),
      ];
    },
    paymentMethods: function () {
      return this.getPaymentMethods;
    },
    fetchPaymentParams: function () {
      return {
        sort: '-createdAt',
        'page[size]': this.pagination.size,
        'page[number]': this.pagination.number,
        include: 'order,destination-office',
        'fields[payments]':
          'code,updatedAt,total_amount,order,destination-office',
        'fields[orders]': 'origin_code,destination_code,grand_total_price',
        'fields[offices]': 'code',
        'filter[origin_office_uuid]]': this.getUser.office_id,
        'filter[from_date]': this.filter.from_date,
        'filter[to_date]': this.filter.to_date,
        'filter[is_verified]': true,
        'filter[payment_method_type_uuid]':
          this.filter.payment_method_type_uuid,
        'filter[office_bank_uuid]': this.filter.office_bank_uuid,
        ...(this.filter.office?.attributes
          ? { 'filter[destination_office_uuid]': this.filter.office.id }
          : {}),
      };
    },
    bankSelectOptions() {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...this.getBanksByOffice.data.map((bank) => ({
          key: bank.id,
          value: bank.id,
          label: bank.attributes.bank_name,
        })),
      ];
    },
    isFilterUsingBank() {
      if (!this.filter.payment_method_type_uuid) {
        return false;
      }

      const method = this.getPaymentMethodTypes.data.find(
        (type) => type.id === this.filter.payment_method_type_uuid
      );

      return ['Transfer Bank', 'EDC'].includes(method.attributes.name);
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchPayments: 'payments/fetchPayments',
      fetchPaymentMethods: 'payments/fetchPaymentPaymentMethods',
      fetchReportMeta: 'payments/fetchReportMeta',
      dailyReport: 'payment_methods/dailyReport',
      fetchPaymentMethodTypesByOffice:
        'payment_method_types/fetchPaymentMethodTypesByOffice',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
    }),
    handleClickReport: function () {
      this.dailyReport(this.fetchPaymentMethodParams).then((res) => {
        downloadFile(res.data, 'laporan-pembayaran-harian.xlsx');
      });
    },
    handleCollapse(rowIndex, payment) {
      const index = this.collapsedRowsIndex.findIndex(
        (item) => item === rowIndex
      );

      if (index === -1) {
        this.collapsedRowsIndex.push(rowIndex);

        this.setCollapsedRowsIndexDetails(rowIndex, payment);
      } else {
        this.collapsedRowsIndex.splice(index, 1);
      }
    },
    handleChangePage: function (page) {
      this.pagination.number = page;

      this.resetCollapseRows();

      this.loadPayments();
    },
    handleChangeFilter: function () {
      this.resetPage();
      this.resetCollapseRows();

      this.loadPayments();
      this.loadReportMeta();
    },
    handleChangeFilterDebounce: debounce(function () {
      this.resetPage();
      this.resetCollapseRows();

      this.loadPayments();
      this.loadReportMeta();
    }, 500),
    handleChangeFilterDate: function () {
      if (new Date(this.filter.from_date) > new Date(this.filter.to_date)) {
        this.filter.from_date = this.filter.to_date;
      }

      this.handleChangeFilterDebounce();
    },
    handleChangeFilterMethod() {
      this.filter.office_bank_uuid = null;

      this.handleChangeFilter();
    },
    checkIsPaymentMethodHasOfficeBank(paymentId, paymentMethod) {
      return (
        paymentMethod.relationships['office-bank'].data !== null &&
        this.getPaymentMethodOfficeBank(paymentId, paymentMethod).attributes
          .office_bank_type === 'bank'
      );
    },
    getPaymentMethodOfficeBank(paymentId, paymentMethod) {
      return this.getSingleIncluded(
        this.collapsedRowsIndexDetails[paymentId]?.paymentMethods,
        paymentMethod.relationships['office-bank'].data.id
      );
    },
    loadPaymentMethodTypes() {
      this.fetchPaymentMethodTypesByOffice({
        officeId: StorageService.getUser().office_id,
      });
    },
    loadPayments() {
      this.fetchPayments(this.fetchPaymentParams);
    },
    loadReportMeta() {
      this.fetchReportMeta({
        params: {
          'filter[origin_office_uuid]]': this.getUser.office_id,
          'filter[from_date]': this.filter.from_date,
          'filter[to_date]': this.filter.to_date,
          'filter[payment_method_type_uuid]':
            this.filter.payment_method_type_uuid,
          'filter[office_bank_uuid]': this.filter.office_bank_uuid,
          ...(this.filter.office?.attributes
            ? { 'filter[destination_office_uuid]': this.filter.office.id }
            : {}),
        },
      });
    },
    loadOfficeBanks() {
      this.fetchBanksByOffice({
        office_id: this.getUser.office_id,
        'filter[office_bank_type]': 'bank',
        'fields[office-banks]': 'bank_name,account_number',
      });
    },
    async getPaymentMethods(paymentId) {
      return await this.fetchPaymentMethods({
        id: paymentId,
        params: {
          include: 'office-bank',
          'filter[payment_method_type_uuid]':
            this.filter.payment_method_type_uuid,
          'filter[office_bank_uuid]': this.filter.office_bank_uuid,
          'fields[payment-methods]':
            'payment_amount,payment_method_type,office-bank',
          'fields[office-banks]': 'account_number,office_bank_type,bank_name',
        },
      });
    },
    async setCollapsedRowsIndexDetails(index, payment) {
      const paymentMethods = await this.getPaymentMethods(payment.id);

      this.collapsedRowsIndexDetails = {
        ...this.collapsedRowsIndexDetails,
        [index]: {
          paymentMethods: paymentMethods ?? { data: [] },
        },
      };
    },
    resetPage: function () {
      this.pagination.number = 1;
    },
    resetCollapseRows() {
      this.collapsedRowsIndex = [];
      this.collapsedRowsIndexDetails = {};
    },
  },
  created: async function () {
    await this.loadPaymentMethodTypes();

    this.loadPayments();
    this.loadReportMeta();
    this.loadOfficeBanks();
  },
};
</script>
